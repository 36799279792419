<template>
  <div id="app">
    <JsonGenerator />
  </div>
</template>

<script>
import JsonGenerator from './components/JsonGenerator.vue';

export default {
  name: 'App',
  components: {
    JsonGenerator
  }
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  color: #2c3e50;
  font-family: "Arial", sans-serif;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  min-height: 100vh; 
}
</style>

<style lang="scss">
@import '@/custom.scss'; // Ensure custom SCSS is imported
</style>
