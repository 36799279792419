<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><img src="/assets/images/logo.png" alt="Bootstrap" width="30" height="24">Raidable Base Loot Editor</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!-- Add other navbar items here if needed -->
        </ul>
        <input type="file" @change="uploadJson" class="btn btn-sm btn-secondary me-2" />
        <button @click="downloadJson" class="btn btn-sm btn-success me-2">
          <font-awesome-icon :icon="['fas', 'download']" /> Download JSON
        </button>
        <button type="button" class="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#resetModal">
          <font-awesome-icon :icon="['fas', 'undo']" /> Reset
        </button>
      </div>
    </div>
    <div class="modal fade" id="resetModal" tabindex="-1" aria-labelledby="resetModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-dark text-white">
          <div class="modal-header">
            <h5 class="modal-title" id="resetModalLabel">Confirm Reset</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure you want to reset all items? This action cannot be undone.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-danger" @click="confirmReset">Reset</button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  name: 'AppNavbar',
  props: ['downloadJson', 'resetItems', 'loadJson'],
  methods: {
    uploadJson(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const jsonContent = JSON.parse(e.target.result);
          this.loadJson(jsonContent);
        };
        reader.readAsText(file);
      }
    },
    confirmReset() {
      this.resetItems();
      // Close the modal
      const modalElement = document.getElementById('resetModal');
      const modalInstance = Modal.getInstance(modalElement);
      modalInstance.hide();
    }
  }
};
</script>

<style lang="scss">
@import '@/custom.scss'; // Ensure custom SCSS is imported
</style>
