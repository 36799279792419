<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <h5 class="text-center text-white mt-2">Items</h5>
          <div class="row">
            <div class="col">
              <input type="text" v-model="searchItem" class="form-control form-control-sm mb-3" placeholder="Search items...">
            </div>
          </div>
          <div class="row" style="max-height: 90vh; overflow-y: auto;">
            <div 
              v-for="item in filteredItems" 
              :key="item.id" 
              class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
              @click="addPredefinedItem(item)"
              style="cursor: pointer; position: relative; display: flex; justify-content: center; align-items: center;"
            >
              <div class="position-relative">
                <img :src="item.image" alt="" class="img-thumbnail item-image bg-dark">
                <div class="item-overlay" style="font-size: smaller;">{{ item.name }}</div>
                <span v-if="isItemAdded(item)" class="green-dot"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <AppNavbar :downloadJson="downloadJson" :resetItems="resetItems" :loadJson="loadJson" />
          <h5 class="text-white mt-2 ms-3">Selected Items</h5>
          <div class="items-container" style="max-height: calc(100vh - 120px); overflow-y: auto;">
            <div class="row">
              <div v-for="(item, index) in items" :key="index" class="col-lg-3 mb-3">
                <div class="card h-100 bg-dark">
                  <div class="card-body d-flex justify-content-between align-items-center">
                    <img :src="item.image" class="img-thumbnail bg-dark" alt="...">
                    <div class="ms-3 flex-grow-1">
                      <span class="text-white">{{ item.name }}</span>
                    </div>
                    <button type="button" @click="removeItem(index)" class="btn btn-danger btn-sm ms-auto">
                      <font-awesome-icon :icon="['fas', 'trash']" />
                    </button>
                  </div>
                  <form class="mt-0">
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="input-group input-group-sm">
                          <span class="input-group-text">BP</span>
                          <select v-model="item.hasBlueprint" class="form-select">
                            <option :value="true">True</option>
                            <option :value="false">False</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group input-group-sm">
                          <span class="input-group-text">Skin</span>
                          <input type="number" v-model="item.skin" class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="input-group input-group-sm">
                          <span class="input-group-text">Min</span>
                          <input type="number" v-model="item.min" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group input-group-sm">
                          <span class="input-group-text">Max</span>
                          <input type="number" v-model="item.max" class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="input-group input-group-sm">
                          <span class="input-group-text">Probability</span>
                          <input type="number" v-model.number="item.probability" class="form-control" step="0.1" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group input-group-sm">
                          <span class="input-group-text">Stacksize</span>
                          <input type="number" v-model="item.stacksize" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <pre class="bg-light p-3">{{ items }}</pre> -->
      <!-- Toast Notification -->
      <div class="toast-container position-fixed top-0 end-0 p-3 mt-5" style="z-index: 11">
        <div 
          class="toast" 
          role="alert" 
          aria-live="assertive" 
          aria-atomic="true" 
          ref="toast"
        >
          <div class="toast-header">
            <strong class="me-auto">Notification</strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body" v-html="toastMessage">
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import predefinedItems from '@/assets/predefinedItems.json'; // Adjust the path if needed
import { Toast } from 'bootstrap'; // Import Toast from Bootstrap
import AppNavbar from './AppNavbar.vue'; // Adjust the path if needed

export default {
  components: {
    AppNavbar
  },
  data() {
    return {
      items: [],
      predefinedItems: predefinedItems,
      searchItem: '',
      toastMessage: '' // Toast message state
    };
  },
  computed: {
    filteredItems() {
      return this.predefinedItems.filter(item => 
        item.name.toLowerCase().includes(this.searchItem.toLowerCase())
      );
    }
  },
  created() {
    this.loadItemsFromCache();
  },
  watch: {
    items: {
      handler(newItems) {
        this.saveItemsToCache(newItems);
      },
      deep: true,
    }
  },
  methods: {
    addPredefinedItem(item) {
      if (this.items.find(i => i.shortname === item.shortname)) {
        this.showToast(`Item <b>${item.name}</b> is already added!`);
      } else {
        this.items.push({ ...item, probability: 1.0, stacksize: -1, skin: 0, hasBlueprint: false });
      }
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    downloadJson() {
      const jsonStructure = this.items.map(item => ({
        shortname: item.shortname,
        name: item.name || null,
        blueprint: item.hasBlueprint,
        skin: item.skin,
        amount: item.max,
        amountMin: item.min,
        probability: parseFloat(item.probability.toFixed(1)), // Ensure probability is a number with one decimal
        stacksize: item.stacksize
      }));
      const blob = new Blob([JSON.stringify(jsonStructure, null, 2)], { type: 'application/json' });
      saveAs(blob, 'Raidable_Bases_Loot.json');
      this.resetItems(); // Reset items after downloading
    },
    resetItems() {
      this.items = [];
      localStorage.removeItem('items');
    },
    loadJson(jsonContent) {
      this.items = jsonContent.map(item => {
        const predefinedItem = this.predefinedItems.find(pre => pre.shortname === item.shortname) || {};
        return {
          shortname: item.shortname,
          name: item.name || predefinedItem.name || null,
          image: predefinedItem.image || '',
          hasBlueprint: item.blueprint,
          skin: item.skin,
          min: item.amountMin,
          max: item.amount,
          probability: parseFloat(item.probability.toFixed(1)),
          stacksize: item.stacksize
        };
      });
    },
    showToast(message) {
      this.toastMessage = message;
      const toastEl = this.$refs.toast;
      const toast = new Toast(toastEl);
      toast.show();
    },
    saveItemsToCache(items) {
      localStorage.setItem('items', JSON.stringify(items));
    },
    loadItemsFromCache() {
      const cachedItems = localStorage.getItem('items');
      if (cachedItems) {
        this.items = JSON.parse(cachedItems);
      }
    },
    formattedProbability(probability) {
      return parseFloat(probability).toFixed(1);
    },
    updateProbability(event, index) {
      const value = parseFloat(event.target.value);
      if (!isNaN(value)) {
        this.items[index].probability = value;
      }
    },
    isItemAdded(item) {
      return this.items.some(i => i.shortname === item.shortname);
    }
  }
};
</script>

<style scoped>
.container-fluid {
  overflow-x: hidden; /* Hide horizontal overflow */
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.col-2,
.col-10 {
  padding-left: 0;
  padding-right: 0;
}

div[style*="max-height: 90vh; overflow-y: auto;"] {
  overflow-x: hidden; /* Hide horizontal overflow */
  width: 100%;
}

.items-container {
  max-height: calc(100vh - 120px); /* Adjust the value as needed */
  overflow-y: auto;
}

.item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 5px 0;
  font-size: 12px; /* Adjust font size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

div[style*="cursor: pointer"]:hover .item-overlay {
  opacity: 1;
}

.img-thumbnail.item-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.green-dot {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
}
</style>
