import { createApp } from 'vue';
import App from './App.vue';
import './custom.scss'; // Ensure this is imported before any other CSS

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Add icons to the library
library.add(faDownload, faTrash, faUndo);

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
